import { axiosHandler } from "../helpers/axios";
import api from "./api";

const userService = {
  fetchUsers: (params?, config?) => api.get("/users", { params, ...config }),
  fetchMe: (params?, config?) => api.get("/users/me", { params, ...config }),
  fetchUser: (userID, config?) => api.get(`/users/${userID}`, { ...config }),
  patchUser: (body) => api.patch("/users", body),
  deleteUser: () => api.delete("/users"),
  changePassword: (body) => api.post("/users/change-password", body),
  setPassword: (body) => api.post("/users/set-password", body),
  uploadAvatar: (body) => api.post("/users/avatar", body),
  deleteAvatar: () => api.delete("/users/avatar"),
  getMergeToken: (body) => api.post("/users/merge-token", body),
  mergeRequest: (userID, body) => api.post(`/users/${userID}/merge`, body),
  patchUserSettings: (userID: number, body: PatchUserSettingsBody) =>
    axiosHandler<PatchUserSettingsResponse>(
      api.patch(`/users/${userID}/settings`, body)
    ),
};

export default userService;

import React, { ReactElement, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import isEmail from "validator/lib/isEmail";

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import BaseModal from "../modal/base-modal";
import ModalFooter from "../modal/modal-footer";
import CancelButton from "../common/cancel-button";
import ErrorMessage from "../common/error-message";
import Label from "../common/label";
import ConfirmationButton from "../common/confirmation-button";
import i18n from "../../helpers/i18n";
import Textarea from "../common/textarea";
import textarea from "../../styles/textarea";
import helpService from "../../services/help-service";
import TextInput from "../common/text-input";
import useAppStores from "../../context/app-context";

const content = css`
  padding: ${variables.spaceBase} ${variables.spaceMedium};
  overflow: auto;
  max-height: 85vh;
  display: grid;
  grid-gap: ${variables.spaceBase};
`;

const footer = css`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  border-top: ${pxToRem(1)} solid ${variables.colorSeparator};
  padding: ${variables.spaceTiny} ${variables.spaceBase};
`;

type Input = {
  isOpen: boolean;
  onRequestClose: () => void;
  onSuccess: () => void;
};

const HelpModal = observer(
  ({ isOpen = false, onSuccess, onRequestClose }: Input): ReactElement => {
    const { userStore } = useAppStores();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isNameValid, setIsNameValid] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [isDescriptionValid, setIsDescriptionValid] = useState(null);
    const [serverError, setServerError] = useState("");

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(null);

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(null);

    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState(null);

    useEffect(() => {
      if (userStore.user) {
        setName(userStore.user.name);
        setEmail(userStore.user.email);
      }
    }, [userStore.user]);

    const submit = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsSubmitting(true);

      let hasError = false;

      setIsNameValid(null);
      setNameError(null);
      setIsEmailValid(null);
      setEmailError(null);
      setIsDescriptionValid(null);
      setDescriptionError(null);
      setServerError(null);

      if (name) {
        setIsNameValid(true);
      } else {
        hasError = true;
        setIsNameValid(false);
        setNameError(i18n.t("xIsRequired", { name: i18n.t("name") }));
      }

      if (email && isEmail(email)) {
        setIsEmailValid(true);
      } else if (!email) {
        hasError = true;
        setIsEmailValid(false);
        setEmailError(i18n.t("xIsRequired", { name: i18n.t("email") }));
      } else if (email && !isEmail(email)) {
        hasError = true;
        setIsEmailValid(false);
        setEmailError(i18n.t("invalidEmailAddress"));
      }

      if (description) {
        setIsDescriptionValid(true);
      } else {
        hasError = true;
        setIsDescriptionValid(false);
        setDescriptionError(
          i18n.t("xIsRequired", { name: i18n.t("description") })
        );
      }

      if (hasError) {
        setIsSubmitting(false);
        return;
      }

      try {
        await helpService.sendHelp({
          name,
          email,
          description,
        });

        toast.success(i18n.t("weWillGetBackToYou"));
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        console.error(error);

        if (error?.response?.data?.errors) {
          if (error?.response?.data?.errors?.name) {
            setIsNameValid(false);
            setNameError(error?.response?.data?.errors?.name);
          }
          if (error?.response?.data?.errors?.email) {
            setIsEmailValid(false);
            setEmailError(error?.response?.data?.errors?.email);
          }
          if (error?.response?.data?.errors?.description) {
            setIsDescriptionValid(false);
            setDescriptionError(error?.response?.data?.errors?.description);
          }
        } else if (error?.response?.data?.message) {
          setServerError(error?.response?.data?.message);
        } else {
          setServerError(i18n.t("somethingWentWrong"));
        }
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <BaseModal
        title={i18n.t("howCanWeHelp")}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
      >
        <form onSubmit={isSubmitting === false && submit} noValidate>
          <div css={content}>
            <Label label={i18n.t("name")} htmlFor="help-name-input">
              <TextInput
                id="help-name-input"
                name="help-name-input"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                isValid={isNameValid}
                errorMessage={nameError}
              />
            </Label>
            <Label label={i18n.t("email")} htmlFor="help-email-input">
              <TextInput
                id="help-email-input"
                name="help-email-input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isValid={isEmailValid}
                errorMessage={emailError}
              />
            </Label>
            <Label
              label={i18n.t("describeTheProblemYouAreHaving")}
              htmlFor="help-description-input"
            >
              <Textarea
                style={textarea}
                id="help-description-input"
                name="help-description-input"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                autoComplete="off"
                autoFocus={true}
                minRows={5}
                isValid={isDescriptionValid}
                errorMessage={descriptionError}
              />
            </Label>
            {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
          </div>
          <ModalFooter style={footer}>
            <CancelButton onClick={onRequestClose} disabled={isSubmitting}>
              {i18n.t("cancel")}
            </CancelButton>
            <ConfirmationButton type="submit" disabled={isSubmitting}>
              {i18n.t("send")}
            </ConfirmationButton>
          </ModalFooter>
        </form>
      </BaseModal>
    );
  }
);

export default HelpModal;

import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";

const getSuccessResponse = <T>({
  config,
  data,
  request,
}: AxiosResponse): AxiosCustomResponse<T> => {
  return {
    payload: data,
    success: true,
    request,
    config,
  };
};

const getErrorResponse = <T>(
  error: AxiosError
): AxiosCustomResponse<T, ErrorData> => {
  const { config, response, request } = error;
  return {
    error: response?.data,
    success: false,
    isCancelled: axios.isCancel(error),
    request,
    config,
  };
};

export const axiosHandler = <T = any>(
  axiosFunc: Promise<T>,
  handlerOptions: HandlerOptions = {}
) => {
  const { notiOnError = true } = handlerOptions;

  return axiosFunc
    .then((res) => getSuccessResponse(res as any) as AxiosCustomResponse<T>)
    .catch((error) => {
      const response = getErrorResponse<T>(error);

      if (!response.isCancelled && notiOnError) {
        const message = response.error?.message;
        if (typeof message === "string") {
          toast.error(message);
        } else if (
          (message as Record<string, any>)?.body &&
          Array.isArray((message as Record<string, any>).body)
        ) {
          toast.error((message as any).body.join("\n"));
        }
      }

      return response;
    });
};

import { css } from "@emotion/react";

import variables from "./variables";
import pxToRem from "./px-to-rem";

const textarea = css`
  appearance: none;
  width: 100%;
  padding: ${pxToRem(12)} 1rem;
  border: ${pxToRem(1)} solid ${variables.colorSecondaryGray};
  border-radius: ${variables.roundness1};
  font-family: ${variables.baseFontFamily};
  font-size: ${pxToRem(14)};
  outline: none;
  transition: border-color ${variables.transitionSpeed} ease;

  &:focus {
    border-color: ${variables.colorHighlightBlue};
  }

  &:disabled {
    border-color: ${variables.colorSecondaryGray};
    cursor: not-allowed;
    background: ${variables.colorContainerGray};
  }
`;

export default textarea;

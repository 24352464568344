import React, { ReactElement, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/react/types";
import TextareaAutosize from "react-textarea-autosize";
import Hotkeys from "react-hot-keys";

import variables from "../../styles/variables";
import ErrorMessage from "./error-message";
import { mergeRefs } from "../../helpers/form";

const input = css`
  appearance: none;
  width: 100%;
  padding: 0;
  border: none;
  font-family: ${variables.baseFontFamily};
  font-size: 1rem;
  outline: none;
  text-overflow: ellipsis;
  transition: border-color ${variables.transitionSpeed};

  &:focus {
    border-color: ${variables.colorHighlightBlue};
  }

  &:disabled {
    border-color: ${variables.colorSecondaryGray};
    cursor: not-allowed;
  }
`;

const validStyle = css`
  border-color: ${variables.colorMainGreen};
`;

const invalidStyle = css`
  border-color: ${variables.colorError};
`;

type Input = {
  style?: SerializedStyles;
  id: string;
  name: string;
  value: string;
  placeholder?: string;
  isValid?: boolean;
  errorMessage?: string;
  autoComplete?: string;
  minRows?: number;
  maxRows?: number;
  autoFocus?: boolean;
  dataTest?: string;
  onChange: (SyntheticEvent) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  onSubmitRequest?: (any) => void;
  dataIntercomTarget?: string;
};

const Textarea = React.forwardRef<HTMLInputElement, Input>(
  (
    {
      id,
      name,
      value = "",
      placeholder = "",
      errorMessage,
      isValid,
      style,
      onChange,
      onFocus,
      onBlur,
      minRows = 1,
      maxRows,
      dataTest,
      autoComplete = "off",
      autoFocus = false,
      disabled = false,
      onSubmitRequest,
      dataIntercomTarget,
    }: Input,
    ref
  ): ReactElement => {
    const inputRef = useRef<HTMLInputElement>();

    const onKeyDown = (keyName, e) => {
      if (["command+enter", "ctrl+enter"].includes(keyName)) {
        if (onSubmitRequest) onSubmitRequest(e);
      }
    };

    useEffect(() => {
      if (autoFocus) {
        setTimeout(() => {
          inputRef?.current?.focus();
        });
      }
    }, [autoFocus]);

    return (
      <div data-intercom-target={dataIntercomTarget}>
        <Hotkeys
          keyName="command+enter,ctrl+enter"
          filter={() => {
            return true;
          }}
          onKeyDown={onKeyDown}
        >
          <TextareaAutosize
            ref={mergeRefs(ref, inputRef)}
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            css={[
              input,
              style,
              isValid === true && validStyle,
              isValid === false && invalidStyle,
            ]}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            minRows={minRows}
            maxRows={maxRows}
            autoComplete={autoComplete}
            data-test={dataTest}
            disabled={disabled}
          />
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Hotkeys>
      </div>
    );
  }
);

Textarea.displayName = "Textarea";

export default Textarea;
